
import { addAPI, editAPI } from '../api'
import {
  monitorContentOption,
  deviceTypeEnumOption,
  warningContentOption,
  cameraContentOption,
  carControlContentOption
} from '../config'
import { defineComponent, reactive, ref, toRefs, watchEffect } from 'vue'
import { ElMessage } from 'element-plus'
import { toResult } from '@/utils/toResult'

export default defineComponent({
  name: '',
  props: {
    modelValue: {
      type: Boolean,
      required: true,
      default: false
    },
    row: {
      type: Object,
      default: () => {}
    },
    isType: {
      type: String,
      default: 'edit'
    },
    factoryList: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const required = {
      required: true,
      message: '必填',
      trigger: ['blur', 'change']
    }

    const refFrom = ref<HTMLElement | any>(null)

    const state = reactive({
      visible: false,
      isUpload: true,
      title: '新建设备型号',
      formData: {
        carControlContent: [],
        cameraContent: [],
        monitorContent: [],
        warningContent: []
      },
      rules: {
        deviceType: [required],
        deviceFactoryUuid: [required],
        deviceMaintainType: [required],
        deviceMaintainUuid: [
          required,
          {
            min: 2,
            max: 8,
            message: '长度范围2-8位',
            trigger: 'blur'
          },
          {
            pattern: /^[0-9a-zA-Z]{2,8}$/,
            message: '限数字和英文字符',
            trigger: 'blur'
          }
        ]
      },
      carControlContentOption,
      cameraContentOption,
      monitorContentOption,
      deviceTypeEnumOption,
      warningContentOption
    })

    watchEffect(() => {
      state.visible = props.modelValue
    })

    /**
     * @description: 异步获取详情
     * @param {*}
     * @return {*}
     */
    const getDetail = async () => {
      state.formData = props.row as any
      state.formData.carControlContent = props.row.carControlContent || []
      state.formData.cameraContent = props.row.cameraContent || []
      state.formData.monitorContent = props.row.monitorContent || []
      state.formData.warningContent = props.row.warningContent || []
    }
    ;(async () => {
      switch (props.isType) {
        case 'add':
          state.title = '新建设备型号'
          break
        case 'edit':
          state.title = '编辑设备型号'
          getDetail()
          break
      }
    })()

    /**
     * @description: 取消（关闭）当前 dialog
     * @param {*} void
     * @return {*}
     */
    const onCancel = (): void => {
      state.visible = false
      emit('update:modelValue', false)
      emit('update:list')
    }

    /**
     * @description: 表单提交
     * @param {*}
     * @return {*}
     */
    const onSubmit = async p => {
      let requestAPI = addAPI
      if (props.isType === 'edit') requestAPI = editAPI
      const [err, res, msg] = await toResult(requestAPI(p))
      if (err) return
      console.log(res)
      ElMessage.success(msg || '成功')
      onCancel()
    }

    /**
     * @description: 表单提交前校验
     * @param {*}
     * @return {*}
     */
    const onValidate = () => {
      console.log(refFrom.value)
      refFrom.value.validate((valid: boolean) => {
        if (!valid) return
        onSubmit({
          ...state.formData
        })
      })
    }

    return {
      ...toRefs(state),
      onCancel,
      onValidate,
      refFrom
    }
  }
})
