
import { addFactoryAPI, editFactoryAPI } from '../api'

import { defineComponent, reactive, ref, toRefs, watchEffect } from 'vue'
import { ElMessage } from 'element-plus'
import { toResult } from '@/utils/toResult'

export default defineComponent({
  name: '',
  props: {
    modelValue: {
      type: Boolean,
      required: true,
      default: false
    },
    row: {
      type: Object,
      default: () => {}
    },
    isType: {
      type: String,
      default: 'edit'
    }
  },
  setup(props, { emit }) {
    const required = {
      required: true,
      message: '必填',
      trigger: ['blur', 'change']
    }

    const refFrom = ref<HTMLElement | any>(null)

    const state = reactive({
      visible: false,
      isUpload: true,
      title: '新建设备厂商',
      formData: ref<any>({}),
      rules: {
        deviceFactoryName: [required],
        deviceFactoryUuid: [
          required,
          {
            pattern: /^[0-9a-zA-Z]{1,6}$/,
            message: '限数字和英文字符',
            trigger: 'blur'
          },
          {
            min: 1,
            max: 6,
            message: '长度范围1-6位',
            trigger: 'blur'
          }
        ]
      }
    })

    watchEffect(() => {
      state.visible = props.modelValue
    })
    /**
     * @description: 异步获取详情
     * @param {*}
     * @return {*}
     */
    const getDetail = async () => {
      state.formData.deviceFactoryUuid = props.row.deviceFactoryUuid
      state.formData.deviceFactoryName = props.row.deviceFactoryName
      state.formData.factoryId = props.row.id
    }
    ;(async () => {
      switch (props.isType) {
        case 'add':
          state.title = '新建设备厂商'
          break
        case 'edit':
          state.title = '修改设备厂商'
          getDetail()
          break
      }
    })()

    /**
     * @description: 取消（关闭）当前 dialog
     * @param {*} void
     * @return {*}
     */
    const onCancel = (): void => {
      state.visible = false
      state.formData = {}
      emit('update:modelValue', false)
      emit('update:list')
    }

    /**
     * @description: 表单提交
     * @param {*}
     * @return {*}
     */
    const onSubmit = async p => {
      let requestAPI = addFactoryAPI
      if (props.isType === 'edit') requestAPI = editFactoryAPI
      const [err, res, msg] = await toResult(requestAPI(p))
      if (err) return
      console.log(res)
      ElMessage.success(msg || '成功')
      onCancel()
    }

    /**
     * @description: 表单提交前校验
     * @param {*}
     * @return {*}
     */
    const onValidate = () => {
      console.log(refFrom.value)
      refFrom.value.validate((valid: boolean) => {
        if (!valid) return
        onSubmit({
          ...state.formData
        })
      })
    }

    return {
      ...toRefs(state),
      onCancel,
      onValidate,
      refFrom
    }
  }
})
