/*
 * @Description:
 * @Author: yaoxiaoxiao
 * @RearEnd:
 * @Date: 2021-07-08 10:43:57
 * @LastEditors: yaoxiaoxiao
 * @LastEditTime: 2021-08-10 14:48:41
 */
import service from '@/utils/request'

// 主列表
const LIST_PATH = '/device/maintain/page'
const ADD_FACTORY_PATH = '/device/factory/add'
const EDIT_FACTORY_PATH = '/device/factory/edit'
const DEL_FACTORY_PATH = '/device/factory/delete'
const ADD_PATH = '/device/maintain/add'
const DEL_PATH = '/device/maintain/delete'
const EDIT_PATH = '/device/maintain/edit'
const FACTORY_LIST_PATH = '/device/factory/list'
/**
 * @description: 列表分页查询
 * @param {any} data
 * @return {object}
 */
export const getListAPI = (data: any): object => {
  return service.request({
    url: LIST_PATH,
    method: 'post',
    data
  })
}
/**
 * @description: 新增设备
 * @param {any} data
 * @return {object}
 */
export const addAPI = (data: any): object => {
  return service.request({
    url: ADD_PATH,
    method: 'post',
    data
  })
}
/**
 * @description: 厂商列表分页查询
 * @param {any} data
 * @return {object}
 */
export const getFactoryListAPI = (data: any): object => {
  return service.request({
    url: FACTORY_LIST_PATH,
    method: 'post',
    data
  })
}

/**
 * @description: 添加厂商
 * @param {any} data
 * @return {object}
 */
export const addFactoryAPI = (data: any): object => {
  return service.request({
    url: ADD_FACTORY_PATH,
    method: 'post',
    data
  })
}
/**
 * @description: 删除厂商
 * @param {any} data
 * @return {object}
 */
export const delFactoryAPI = (data: any): object => {
  return service.request({
    url: DEL_FACTORY_PATH,
    method: 'post',
    data
  })
}
/**
 * @description: 编辑
 * @param {any} data
 * @return {object}
 */
export const editAPI = (data: any): object => {
  return service.request({
    url: EDIT_PATH,
    method: 'post',
    data
  })
}
/**
 * @description: 删除
 * @param {any} data
 * @return {object}
 */
export const delAPI = (data: any): object => {
  return service.request({
    url: DEL_PATH,
    method: 'post',
    data
  })
}
/**
 * @description: 编辑厂商
 * @param {any} data
 * @return {object}
 */
export const editFactoryAPI = (data: any): object => {
  return service.request({
    url: EDIT_FACTORY_PATH,
    method: 'post',
    data
  })
}
