
import { btnName } from '@/utils/index'
import { getListAPI, getFactoryListAPI, delFactoryAPI, delAPI } from './api'
import { ElMessage } from 'element-plus'
import CTable from '@/components/c-table'
import { defineComponent, reactive, toRefs } from 'vue'
import {
  getTableConfig,
  monitorContent,
  warningContent,
  cameraContent,
  carControlContent
} from './config'
import { toResult } from '@/utils/toResult'
import Dialog from './components/dialog.vue'
import equipDialog from './components/equipDialog.vue'
import { usePagination } from '@/hooks/usePagination'
export default defineComponent({
  name: '',
  components: { CTable, Dialog, equipDialog },
  setup() {
    // 组件全局响应状态
    const state = reactive({
      dataSource: getTableConfig(),
      searchParams: null,
      isShow: false,
      isEquipShow: false,
      currentRow: {},
      countData: {},
      // add or edit or view
      isType: 'eidt',
      monitorContent,
      warningContent,
      cameraContent,
      carControlContent,
      factoryList: [],
      tableList: []
    })
    /**
     * @description: 数据回显
     * @param {*}
     * @return {void}
     */
    const setTransferData = (
      params: number[] | null,
      options: object
    ): string => {
      if (!params) return ''
      return params.reduce((i, c) => {
        i = i ? i + '、' + options[c] : options[c]
        return i
      }, '')
    }
    /**
     * @description: 异步获取列表数据
     * @param {*}
     * @return {void}
     */
    const getList = async (params: object): Promise<void> => {
      state.dataSource.loading = true
      const p = {
        currPage: state.dataSource.pagination.currentPage,
        pageSize: state.dataSource.pagination.pageSize,
        ...params
      }
      const [err, data] = await toResult(getListAPI(p))
      state.dataSource.loading = false
      if (err) return
      state.tableList = data.list || []
      state.dataSource.pagination.total = data.total || 0
    }
    /**
     * @description: 异步获取厂商列表数据
     * @param {*}
     * @return {void}
     */
    const getFactoryList = async (params: object): Promise<void> => {
      const [err, data] = await toResult(getFactoryListAPI(params))
      state.dataSource.loading = false
      if (err) return
      state.factoryList = data
    }
    /**
     * @description: 执行查询
     * @param {object} params
     * @return {void}
     */
    const handleSearch = async (): Promise<void> => {
      state.dataSource.data = []
      await getFactoryList({})
      await getList(null)
      // const factoryCopy = state.factoryList.slice()
      // for (const val of factoryCopy) {
      //   val.children = []
      //   for (const [key, value] of state.tableList.entries()) {
      //     if (value.deviceFactoryUuid === val.deviceFactoryUuid) {
      //       val.children.push(value)
      //     }
      //     value.id = value.factoryId + +key + Math.random() * 100
      //     for (const key in value) {
      //       if (Array.isArray(value[key])) {
      //         if (value[key + 'Text']) continue
      //         value[key + 'Text'] = setTransferData(
      //           value[key] as number[],
      //           state[key]
      //         )
      //       }
      //     }
      //   }
      // }
      for (const value of state.tableList) {
        if (value.children && !value.children.length) continue
        for (const v of value.children) {
          for (const key in v) {
            if (Array.isArray(v[key])) {
              if (v[key + 'Text']) continue
              v[key + 'Text'] = setTransferData(v[key] as number[], state[key])
            }
          }
        }
      }
      state.dataSource.data = state.tableList
    }

    // setup 时候执行一次
    handleSearch()
    // c-table pagination method hook
    const { pageCurrentChange, pageSizeChange } = usePagination(
      state.dataSource.pagination,
      () => {
        handleSearch()
      }
    )
    /**
     * @description: 切换显示组件
     * @param {*} attr 控制组件显示的属性名称
     * @param {*} row 选中列表的行数据
     * @param {*} type 是编辑还是查看 edit || view
     * @return {*}
     */
    const onToggle = (attr: string, row?, type?, itemType?): void => {
      state[attr] = !state[attr]
      row && (state.currentRow = row)
      type && (state.isType = type)
      itemType && (state.isType = type)
    }
    const delAction = async row => {
      if (row && row.children) {
        const [err, , msg] = await toResult(
          delFactoryAPI({ factoryId: row.id })
        )
        if (err) return
        ElMessage.success(msg)
        handleSearch()
      } else {
        const [err, , msg] = await toResult(
          delAPI({ maintainId: row.maintainId })
        )
        if (err) return
        ElMessage.success(msg)
        handleSearch()
      }
    }
    return {
      ...toRefs(state),
      btnName,
      handleSearch,
      onToggle,
      pageCurrentChange,
      pageSizeChange,
      delAction
    }
  }
})
