import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "main-bg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_c_table = _resolveComponent("c-table")
  const _component_Dialog = _resolveComponent("Dialog")
  const _component_equip_dialog = _resolveComponent("equip-dialog")
  const _directive_hasAuth = _resolveDirective("hasAuth")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_el_button, {
      type: "primary",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onToggle('isEquipShow', null, 'add')))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.btnName('tpm-factory-add') || '新建设备厂商'), 1)
      ]),
      _: 1
    }, 512), [
      [_directive_hasAuth, 'tpm-factory-add']
    ]),
    _withDirectives(_createVNode(_component_el_button, {
      type: "primary",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onToggle('isShow', null, 'add')))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.btnName('tpm-add') || '添加设备型号'), 1)
      ]),
      _: 1
    }, 512), [
      [_directive_hasAuth, 'tpm-add']
    ]),
    _createVNode(_component_c_table, {
      rowKey: "id",
      dataSource: _ctx.dataSource,
      onPageCurrentChange: _ctx.pageCurrentChange,
      onPageSizeChange: _ctx.pageSizeChange,
      class: "mt20"
    }, {
      action: _withCtx((data) => [
        _withDirectives(_createVNode(_component_el_button, {
          type: "text",
          size: "small",
          onClick: ($event: any) => (_ctx.delAction(data.row))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.btnName('tmp-delete') || '删除'), 1)
          ]),
          _: 2
        }, 1032, ["onClick"]), [
          [_directive_hasAuth, 'tpm-delete']
        ]),
        _withDirectives(_createVNode(_component_el_button, {
          type: "text",
          size: "small",
          onClick: ($event: any) => (
            _ctx.onToggle(
              data.row.children ? 'isEquipShow' : 'isShow',
              data.row,
              'edit'
            )
          )
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(data.row.children ? '编辑厂商' : '编辑型号'), 1)
          ]),
          _: 2
        }, 1032, ["onClick"]), [
          [_directive_hasAuth, 'tpm-edit']
        ])
      ]),
      _: 1
    }, 8, ["dataSource", "onPageCurrentChange", "onPageSizeChange"]),
    (_ctx.isShow)
      ? (_openBlock(), _createBlock(_component_Dialog, {
          key: 0,
          modelValue: _ctx.isShow,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.isShow = $event)),
          row: _ctx.currentRow,
          isType: _ctx.isType,
          factoryList: _ctx.factoryList,
          "onUpdate:list": _ctx.handleSearch
        }, null, 8, ["modelValue", "row", "isType", "factoryList", "onUpdate:list"]))
      : _createCommentVNode("", true),
    (_ctx.isEquipShow)
      ? (_openBlock(), _createBlock(_component_equip_dialog, {
          key: 1,
          modelValue: _ctx.isEquipShow,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.isEquipShow = $event)),
          row: _ctx.currentRow,
          isType: _ctx.isType,
          "onUpdate:list": _ctx.handleSearch
        }, null, 8, ["modelValue", "row", "isType", "onUpdate:list"]))
      : _createCommentVNode("", true)
  ]))
}