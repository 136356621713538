/*
 * @Description: app更新配置
 * @Author: yaoxiaoxiao
 * @RearEnd:
 * @Date: 2021-07-08 10:27:33
 * @LastEditors: yaoxiaoxiao
 * @LastEditTime: 2021-08-30 16:53:42
 */

import { enumToOption } from '@/utils'

// 设备类型
export enum deviceTypeEnum {
  '远程控车（CAT-1）',
  '接单外设（GPad）',
  '智能算法（Tbox）',
  '车机（虎鲸安驾2.0）',
  其他类型
}
export enum monitorContent {
  音频监控 = 1,
  定位监控,
  视频监控
}
export enum warningContent {
  司机报警 = 1,
  乘客报警
}
export enum cameraContent {
  '驾驶员摄像头（1）' = 1,
  '车正前方摄像头（2）' = 2,
  '前排摄像头（4）' = 4,
  '后排摄像头（5）' = 5,
  '后备箱摄像头（7）' = 7,
}
export enum carControlContent {
  禁止启动 = 1,
  允许启动,
  开车门,
  闭锁,
  闪灯,
  鸣笛
}
export const deviceTypeEnumOption = enumToOption(deviceTypeEnum)
export const monitorContentOption = enumToOption(monitorContent)
export const warningContentOption = enumToOption(warningContent)
export const cameraContentOption = enumToOption(cameraContent)
export const carControlContentOption = enumToOption(carControlContent)

// 表格配置
export const getTableConfig = () => {
  // table 通用属性
  const common = {
    // table 有多选时设置
    isSelection: false,
    // table loading
    loading: false,
    // table 是否为斑马纹
    stripe: false,
    // table 的尺寸 medium / small / mini
    size: 'small',
    // table 是否开启索引
    isIndex: false,
    // table 索引 label
    indexLabel: '编号',
    // table 数据
    data: []
  }

  // table 分页
  const pagination = {
    // 是否展示分页
    isShow: true,
    // 总条数
    total: 0,
    // 每页显示条目个数
    pageSize: 20,
    // 当前页数
    currentPage: 1,
    // 每页显示个数选择器的选项设置
    pageSizes: [10, 20, 40, 80, 100]
  }

  // table 列数据
  const column = [
    {
      key: 'deviceFactoryName',
      label: '设备厂商',
      width: '120'
    },
    {
      key: 'deviceMaintainType',
      label: '设备型号',
      width: '120'
    },
    {
      key: 'monitorContentText',
      label: '监控功能',
      width: '120'
    },
    {
      key: 'carControlContentText',
      label: '控车功能',
      width: '120'
    },
    {
      key: 'warningContentText',
      label: '报警按钮功能',
      width: '100'
    },
    {
      key: 'cameraContentText',
      label: '摄像头数',
      width: '120'
    },
    {
      key: 'action',
      label: '操作',
      width: '120',
      fixed: 'right',
      slot: 'action'
    }
  ]

  return {
    ...common,
    pagination,
    column
  }
}
